<template>
  <div style="flex: 1">
    <Iheader></Iheader>
    <main>
  <div class="gradient-bg"></div>
      <div class="wrapper clrfix" style="position: relative">
        <section class="content narrow">
          <h1 style="color: rgba(255, 255, 255, 1);font-size: 24px;">{{ $t("menus.Support") }}</h1>
          <p  style="text-align: center;color: rgba(71, 144, 246, 1);font-size: 16px;">
            {{ $t("support.title") }}
          </p>
          <ul class="support-list clrfix">
            <li>
              <a  id="start_livechat" @click.prevent="handleClickLive" @mouseover="coco" @mouseout="coco1">
                <span class="ico right">
                  <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="&#229;&#174;&#162;&#230;&#156;&#141;-hover ">
<rect id="&#231;&#159;&#169;&#229;&#189;&#162;" opacity="0.01" width="18" height="18" fill="red" style="fill:red;fill-opacity:1;"/>
<path id="&#232;&#183;&#175;&#229;&#190;&#132;" d="M3.62037 14.1626C2.60811 14.1626 1.7875 13.342 1.7875 12.3297V10.4955C1.7875 9.48347 2.60837 8.6626 3.62037 8.6626H3.6245V8.69972C3.6245 4.8841 6.842 1.7876 10.9532 1.7876C15.0521 1.7876 18.26 4.86485 18.282 8.6626H18.2875C19.2995 8.6626 20.1204 9.48347 20.1204 10.4955V12.3297C20.1204 13.3417 19.2995 14.1626 18.2875 14.1626H18.2352C17.8832 18.5337 15.5361 20.1205 11.8704 20.1205V20.0627C11.7208 20.1011 11.5669 20.1205 11.4125 20.1205H10.4954C9.48311 20.1205 8.6625 19.2999 8.6625 18.2876C8.6625 17.2753 9.48311 16.4547 10.4954 16.4547H11.4125C12.3282 16.4547 13.0872 17.1257 13.2234 18.003C15.3587 17.6771 16.4477 16.3901 16.4477 12.9567V10.4955H16.4505V8.69972C16.4505 6.00747 14.146 3.62047 10.9546 3.62047C7.76325 3.62047 5.45462 6.00747 5.45462 8.69972V12.3338C5.45443 12.6024 5.39529 12.8676 5.28137 13.1107L5.247 13.1781C5.23085 13.2104 5.21342 13.2421 5.19475 13.273L5.1645 13.3211C5.082 13.4503 4.98437 13.5672 4.873 13.6703L4.82625 13.7116C4.80419 13.7327 4.78125 13.7529 4.7575 13.7721C4.7355 13.7858 4.7135 13.8023 4.69287 13.8175C4.66533 13.8384 4.63688 13.8581 4.60763 13.8766L4.56638 13.9027C4.53069 13.925 4.49398 13.9457 4.45637 13.9646L4.43575 13.9728C4.3945 13.9948 4.35187 14.0141 4.3065 14.032L4.26388 14.0485C4.11813 14.1035 3.96137 14.1406 3.8005 14.1557L3.62312 14.164H3.62037V14.1626Z" fill="red" :style="{fill:co,fillOpacity:1}"/>
</g>
 
</svg>
                </span>
                <label>{{ $t("support.list1Title") }}</label>
                <p>{{ $t("support.list1Content") }}</p>
              </a>
            </li>
            <li>
              <a @click.prevent="handleClickTelegram">
                <span class="ico right tg"></span>
                <label>{{ $t("support.list2Title") }}</label>
                <p>{{ $t("support.list2Content") }}</p>
              </a>
            </li>
          </ul>
        </section>        
      </div>
    </main>
  </div>
</template>

<script>
import Iheader from "@/components/header.vue";
export default {
  name: "support",
  components: {
    
    Iheader,
  },
  data() {
    return {
     co:'#4790F6'
    };
  },
  methods:{
      handleClickLive(){
          jivo_api.open();
      },
      handleClickTelegram(){
          window.open('https://t.me/cce_cash' , '_blank');
      },
      coco(){
                this.co='#ffffff'
      },
      coco1(){
                this.co='#4790F6'
      }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/css/support.css";
#main-page.support ul.support-list>li>a {
  background-color:rgba(35, 58, 106, 1) ;
  border: none;
  color: rgba(71, 144, 246, 1);
  border-radius: 6px;
  padding:32px;
  height:100%;
  margin: 0 auto;
  p{
    color: #8C91AF;
  }
}
// .wrapper{
//   max-width: 100%;
// }
section.content.narrow{
  max-width: 960px;
}
#main-page.support ul.support-list>li>a:hover{
  background-color:rgba(71, 144, 246, 1);
  color: rgb(255, 255, 255);
  p{
  color: #ffffff;

  }
}
ul.support-list > li .ico.right {
  float: left;
  padding-right: 5px;
  
}
@media screen and (max-width: 600px) {
  #main-page.support ul.support-list>li {
  background-color:rgba(35, 58, 106, 1) ;
  border: none;
  color: rgba(71, 144, 246, 1);
  border-radius: 6px;
  width: 100%;
  height:100%;
 display: block;
  p{
    color: #8C91AF;
  }
}


}

</style>
<style>
  #app{
  overflow: hidden;
}</style>
